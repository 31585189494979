const theme = {
  primaryDark: "#222222",
  primaryLight: "#FFFFFF",
  primaryHover: "#343078",
  cobalt: "#0e0b34",
  bgCobalt: "#0e0b34",
  mobile: "576px"
};

export default theme;
