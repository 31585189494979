import firebase from "firebase/app";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCXNVvBfVMU-y1cJ-JMAW26-8ZvKmfEz7k",
  authDomain: "nenoonsapi.firebaseapp.com",
  databaseURL: "https://nenoonsapi.firebaseio.com",
  projectId: "nenoonsapi",
  storageBucket: "nenoonsapi.appspot.com",
  messagingSenderId: "81019214649",
  appId: "1:81019214649:web:35a41ae42b4f71232966e8",
  measurementId: "G-73Q1PETLCX",
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

export { firebaseConfig, firebase };

const db = firebase.firestore();

export { db };
