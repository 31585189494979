import ApolloClient from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { createUploadLink } from "apollo-upload-client";

const link =
  process.env.NODE_ENV === "production"
    ? createUploadLink({ uri: "https://nenoonsapi.du.r.appspot.com/graphql" })
    : createUploadLink({ uri: "http://192.168.1.152:4002/graphql" });

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
  onError: ({ networkError, graphQLErrors }) => {
    console.log("graphQLErrors", graphQLErrors);
    console.log("networkError", networkError);
  },
});

export default client;
