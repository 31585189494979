/* eslint-disable no-lone-blocks */
import React, { Suspense } from "react";
import * as firebase from "firebase";
import "firebase/firestore";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import ReactNotification from "react-notifications-component";
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";
import "react-notifications-component/dist/theme.css";
import jwtDecode from "jwt-decode";
import { ThemeProvider } from "styled-components";
import useSettings from "stores/settings";
import { observer } from "mobx-react-lite";
import GlobalStyles from "./global";
import theme from "./theme";
import { firebaseConfig } from "./shared/Firebase";

const Home = React.lazy(() => import("containers/home/Home"));
const Info = React.lazy(() => import("containers/store/menu/Info"));
const Customer = React.lazy(() => import("containers/store/menu/Customer"));
const Product = React.lazy(() => import("containers/store/menu/Product"));
const Review = React.lazy(() => import("containers/store/menu/Review"));
const Order = React.lazy(() => import("containers/store/menu/Order"));
const Coupon = React.lazy(() => import("containers/store/menu/Coupon"));
const Reservation = React.lazy(() => import("containers/store/menu/Reservation"));
const Settlement = React.lazy(() => import("containers/settlement/Settlement"));
const Advertisement = React.lazy(() => import("containers/advertisement/Advertisement"));
const Response = React.lazy(() => import("containers/response/Response"));
const Member = React.lazy(() => import("containers/member/Member"));
const Notification = React.lazy(() => import("containers/notifications/Notification"));
const SignIn = React.lazy(() => import("containers/login/SignIn"));
const SignUp = React.lazy(() => import("containers/login/SignUp"));
const Join = React.lazy(() => import("containers/login/Join"));
const PasswordFind = React.lazy(() => import("containers/login/PasswordFind"));
const PasswordFindMessage = React.lazy(() => import("containers/login/PasswordFindMessage"));
const PasswordReset = React.lazy(() => import("containers/login/PasswordReset"));
const StoreRegister = React.lazy(() => import("containers/home/store/StoreRegister"));
const StoreRegisterOk = React.lazy(() => import("containers/home/store/StoreRegisterOk"));
const Page404 = React.lazy(() => import("containers/notFound/NotFound"));

// ** webapp **
const AppLayout = React.lazy(() => import("containers/appweb/LayoutOne"));
const AppStores = React.lazy(() => import("containers/appweb/pages/Stores"));
const AppShopInfo = React.lazy(() => import("containers/appweb/pages/ShopInfo"));
const AppShopAllProduct = React.lazy(() => import("containers/appweb/pages/ShopAllProduct"));
const AppProductDetail = React.lazy(() => import("containers/appweb/pages/AppProductDetail"));
const AppProductHomeOrder = React.lazy(() => import("containers/appweb/pay/AppProductHomeOrder"));
const AppProductStoreOrder = React.lazy(() => import("containers/appweb/pay/AppProductStoreOrder"));
const AppCartProductHomeOrder = React.lazy(() => import("containers/appweb/pay/AppCartProductHomeOrder"));
const AppCartProductStoreOrder = React.lazy(() => import("containers/appweb/pay/AppCartProductStoreOrder"));
const AppLensProductStoreOrder = React.lazy(() => import("containers/appweb/pay/AppLensProductStoreOrder"));

const AppMain = React.lazy(() => import("containers/appweb/pages/AppMain"));
const AppPlaceMain = React.lazy(() => import("containers/appweb/pages/place/PlaceMain"));
const AppWishList = React.lazy(() => import("containers/appweb/pay/AppWishList"));
const AppCart = React.lazy(() => import("containers/appweb/pay/AppCart"));
const AppPayOk = React.lazy(() => import("containers/appweb/pay/AppPayOk"));
const AppPayCancel = React.lazy(() => import("containers/appweb/pay/AppPayCancel"));
const AppPayReturn = React.lazy(() => import("containers/appweb/pay/AppPayReturn"));
const AppEvent = React.lazy(() => import("containers/appweb/noticeBoard/AppEvent"));
const AppEventDetail = React.lazy(() => import("containers/appweb/noticeBoard/AppEventDetail"));
const AppMagazine = React.lazy(() => import("containers/appweb/noticeBoard/AppMagazine"));
const AppMagazineDetail = React.lazy(() => import("containers/appweb/noticeBoard/AppMagazineDetail"));
const AppSplitBanner = React.lazy(() => import("containers/appweb/pages/AppSplitPage/AppHomeBanner"));
const AppHomePage = React.lazy(() => import("containers/appweb/pages/AppSplitPage/AppHomePage"));

// ** webapp ** mypage
const AppMyPage = React.lazy(() => import("containers/appweb/myPage/MyPage"));
const AppUserInfo = React.lazy(() => import("containers/appweb/myPage/AppUserInfo"));
const AppUserInfoEdit = React.lazy(() => import("containers/appweb/myPage/AppUserInfoEdit"));
const AppOrderList = React.lazy(() => import("containers/appweb/myPage/AppOrderList"));
const AppOrderDetail = React.lazy(() => import("containers/appweb/myPage/AppOrderDetail"));
const AppOrderCancel = React.lazy(() => import("containers/appweb/myPage/AppOrderCancel"));
const AppReviewList = React.lazy(() => import("containers/appweb/myPage/AppReviewList"));
const AppCouponList = React.lazy(() => import("containers/appweb/myPage/AppCouponList"));
const AppReservationList = React.lazy(() => import("containers/appweb/myPage/AppReservationList"));
const AppReservationDetail = React.lazy(() => import("containers/appweb/myPage/AppReservationDetail"));
const AppReviewDetail = React.lazy(() => import("containers/appweb/myPage/AppReviewDetail"));
const AppReviewCreate = React.lazy(() => import("containers/appweb/myPage/AppReviewCreate"));
const AppReturnDetail = React.lazy(() => import("containers/appweb/myPage/AppReturnDetail"));
const AppReturnCreate = React.lazy(() => import("containers/appweb/myPage/AppReturnCreate"));
const AppChangeDetail = React.lazy(() => import("containers/appweb/myPage/AppChangeDetail"));
const AppChangeCreate = React.lazy(() => import("containers/appweb/myPage/AppChangeCreate"));
const AppNotice = React.lazy(() => import("containers/appweb/myPage/AppNotice"));
const AppNoticeDetail = React.lazy(() => import("containers/appweb/myPage/AppNoticeDetail"));
const AppQna = React.lazy(() => import("containers/appweb/myPage/AppQna"));
const AppQnaCreate = React.lazy(() => import("containers/appweb/myPage/AppQnaCreate"));
const AppFaq = React.lazy(() => import("containers/appweb/myPage/AppFaq"));
const AppStoreQna = React.lazy(() => import("containers/appweb/myPage/AppStoreQna"));
const AppStoreQnaCreate = React.lazy(() => import("containers/appweb/myPage/AppStoreQnaCreate"));
const AppStoreQnaAgainCreate = React.lazy(() => import("containers/appweb/myPage/AppStoreQnaAgainCreate"));

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const App = observer(() => {
  console.log("process.env.NODE_ENV : ", process.env.NODE_ENV);
  const tokenCheck = window.localStorage.getItem("token");

  const settings = useSettings();

  // 로그인 체크
  if (tokenCheck) {
    const tokenDecode = jwtDecode(tokenCheck);
    const current_time = Date.now() / 1000;
    if (tokenDecode.exp < current_time) {
      /* expired */
      settings.isLogin = false;
      settings.email = "";
      settings.name = "";
      settings.admin = false;
      settings.memberOpen = false;
      settings.memberId = "";
      settings.storeId = "";
      settings.storeGroupId = "";
      settings.token = "";
      window.localStorage.setItem("token", "");
    } else {
      settings.isLogin = true;
      settings.email = tokenDecode.user.email;
      settings.name = tokenDecode.user.name;
      settings.admin = tokenDecode.user.admin;
      settings.memberOpen = tokenDecode.user.memberOpen;
      settings.memberId = tokenDecode.user.id;
      settings.storeId = tokenDecode.user.storeId;
      settings.storeGroupId = tokenDecode.user.storeGroupId;
      settings.token = tokenCheck;
      window.localStorage.setItem("token", tokenCheck);
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyles />
        <BreadcrumbsProvider>
          <Router>
            <div className="min-h-screen flex flex-col">
              <ReactNotification />
              {/* <Suspense fallback={<div></div>}> */}
              <Suspense fallback={<div />}>
                <div className="flex-grow">
                  <Switch>
                    <Route exact path="/" component={Home} />
                    <Route path="/signin" component={SignIn} />
                    <Route path="/signup/:category?" component={SignUp} />
                    <Route path="/join" component={Join} />
                    <Route path="/password-find" component={PasswordFind} />
                    <Route path="/password-find-message" component={PasswordFindMessage} />
                    <Route path="/password-reset/:token" component={PasswordReset} />
                    <Route path="/store-register" component={StoreRegister} />
                    <Route path="/store-register-ok" component={StoreRegisterOk} />
                    <Route path="/home/:category?" component={Home} />
                    {/* 상점관리 */}
                    <Route path="/store-info-menu/:category?/:id?" component={Info} />
                    <Route path="/customer-menu/:category?/:id?" component={Customer} />
                    <Route path="/reservation-menu/:category?/:id?" component={Reservation} />
                    <Route path="/review-menu/:category?/:id?" component={Review} />
                    <Route path="/order-menu/:category?/:id?" component={Order} />
                    <Route path="/product-menu/:category?/:id?" component={Product} />
                    <Route path="/coupon-menu/:category?/:id?" component={Coupon} />
                    {/* 상점관리 외 */}
                    <Route path="/settlement/:category?/:id?" component={Settlement} />
                    <Route path="/advertisement/:category?" component={Advertisement} />
                    <Route path="/response/:category?/:id?" component={Response} />
                    <Route path="/member/:category?/:id?" component={Member} />
                    <Route path="/notification" component={Notification} />
                    {/* app web */}
                    <Route path="/app-banner" component={AppSplitBanner} />
                    <Route path="/app-home-page" component={AppHomePage} />
                    <Route path="/app-main/:category?" component={AppMain} />
                    <Route path="/app-place/:category?" component={AppPlaceMain} />
                    <Route path="/app-wish-list" component={AppWishList} />
                    <Route path="/app-cart" component={AppCart} />
                    <Route path="/app-pay-ok" component={AppPayOk} />
                    <Route path="/app-pay-cancel" component={AppPayCancel} />
                    <Route path="/app-pay-return/:id?/:authDt?/:authNo?/:tid?/:cardName?/:hppNum?" component={AppPayReturn} />
                    <Route path="/app-event" component={AppEvent} />
                    <Route path="/app-event-detail/:id" component={AppEventDetail} />
                    <Route path="/app-magazine" component={AppMagazine} />
                    <Route path="/app-magazine-detail/:id" component={AppMagazineDetail} />
                    <Route path="/app-web" component={AppLayout} />
                    <Route path="/app-stores" component={AppStores} />
                    <Route path="/app-shop-info/:id" component={AppShopInfo} />
                    <Route path="/app-shop-all-product/:id" component={AppShopAllProduct} />
                    <Route path="/app-product-detail/:id" component={AppProductDetail} />
                    <Route path="/app-product-home-order/:id/:productId/:orderList" component={AppProductHomeOrder} />
                    <Route path="/app-product-store-order/:id/:productId/:orderList" component={AppProductStoreOrder} />
                    <Route path="/app-lens-product-order/:id/:productId/:orderList" component={AppLensProductStoreOrder} />
                    <Route path="/app-cart-product-home-order/:id/:orderList" component={AppCartProductHomeOrder} />
                    <Route path="/app-cart-product-store-order/:id/:orderList" component={AppCartProductStoreOrder} />
                    <Route path="/app-my-page" component={AppMyPage} />
                    <Route path="/app-user-info" component={AppUserInfo} />
                    <Route path="/app-user-info-edit" component={AppUserInfoEdit} />
                    <Route path="/app-order-list" component={AppOrderList} />
                    <Route path="/app-order-detail/:id" component={AppOrderDetail} />
                    <Route path="/app-order-cancel/:payId/:orderId" component={AppOrderCancel} />
                    <Route path="/app-review-list" component={AppReviewList} />
                    <Route path="/app-coupon-list" component={AppCouponList} />
                    <Route path="/app-reservation-list" component={AppReservationList} />
                    <Route path="/app-reservation-detail/:id" component={AppReservationDetail} />
                    <Route path="/app-review-detail/:id" component={AppReviewDetail} />
                    <Route path="/app-review-create/:id" component={AppReviewCreate} />
                    <Route path="/app-return-detail/:id" component={AppReturnDetail} />
                    <Route path="/app-return-create/:id" component={AppReturnCreate} />
                    <Route path="/app-change-detail/:id" component={AppChangeDetail} />
                    <Route path="/app-change-create/:id" component={AppChangeCreate} />
                    <Route path="/app-notice" component={AppNotice} />
                    <Route path="/app-notice-detail/:id" component={AppNoticeDetail} />
                    <Route path="/app-qna" component={AppQna} />
                    <Route path="/app-qna-create" component={AppQnaCreate} />
                    <Route path="/app-faq" component={AppFaq} />
                    <Route path="/app-store-qna" component={AppStoreQna} />
                    <Route path="/app-store-qna-create/:type/:id" component={AppStoreQnaCreate} />
                    <Route path="/app-store-qna-again-create/:type/:productId/:parentId/:category" component={AppStoreQnaAgainCreate} />

                    <Route path="*" component={Page404} />
                  </Switch>
                </div>
              </Suspense>
            </div>
          </Router>
        </BreadcrumbsProvider>
      </>
    </ThemeProvider>
  );
});

export default App;
