import "core-js/stable";
import "regenerator-runtime/runtime";
import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { ApolloProvider } from "@apollo/client";
import client from "./apolloClient";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./assets/webAppScroll.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "./assets/main.css";
import "./assets/scss/style.scss";
import "./App.scss";

ReactDOM.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
  document.getElementById("root")
);

serviceWorker.unregister();
