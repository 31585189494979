import { useContext, createContext } from "react";
import * as mobx from "mobx";

// INIPAY 셋팅
const payNextUrl = process.env.NODE_ENV === "production" ? "https://nenoonsapi.du.r.appspot.com" : "http://192.168.1.152:4002";
const payNoti = process.env.NODE_ENV === "production" ? "https://nenoons.com" : "http://192.168.1.152:3000";
const mid = process.env.NODE_ENV === "production" ? "nenoons001" : "INIpayTest";
const hashKey = process.env.NODE_ENV === "production" ? "EGBwIGMopVMKP5m4" : "ItEQKi3rY7uvDS8l";
const clientIp = "123.123.123.123";

// 로그인정보 셋팅
const userId = process.env.NODE_ENV === "production" ? "" : "ckac40hhv0000vk6w61uqlwkm";
const userEmail = process.env.NODE_ENV === "production" ? "" : "email@example.com";
const userName = process.env.NODE_ENV === "production" ? "" : "최예지";
const userTel = process.env.NODE_ENV === "production" ? "" : "01024908955";

const context = createContext(
  mobx.observable({
    // true - 로그인 화면 : false - 회원가입 화면
    // 로그인 정보
    isLogin: false,
    state: false,
    email: "",
    name: "",
    memberId: "",
    storeId: "",
    storeGroupId: "",
    admin: false,
    memberOpen: false,
    storeName: "",
    // 상점 리스트
    stores: [],
    // 상품 리스트
    products: [],
    productList: [],
    pageOfItems: 0,
    // 쿠폰 리스트
    couponList: [],

    // noti Count
    notiCount: {
      total: 0,
      lensReservation: 0,
      pickUpReservation: 0,
      order: 0,
      review: 0,
      qna: 0,
    },

    csTab: "주문취소",

    cartCountChange: true,

    // web-app
    tagTab: "추천상품",

    darkMode: false,

    // 요청받은 색상
    //   Red: "#ffe3ee",
    //   Yellow: "#fafad2",
    //   Green: "#e1ffe1",
    //   Orange: "#fdebc8",
    //   White: "#ffffff",
    //   Blue: "#d7f1fa",

    // 안드로이드에 저장되어있던 색상
    // ff9d9d
    // ffff53
    // b9ff7d
    // ffd165
    // e7e7e7
    // 7bcaff

    lightModeColor: "#ffffff",

    payNextUrl,
    payNoti,

    userId,
    userEmail,
    userName,
    userTel,

    // 결제정보
    mid,
    hashKey,
    clientIp,

    // 토큰
    token: "",
  })
);

const useSettings = () => {
  return useContext(context);
};

export default useSettings;
